<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:navigableDetail="false"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'edimensajeauditoria-grid',
	components: {
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'edimensajeauditoria',
			modelColumnDefs: {
			},
			masterDetailColumnVisibles:{
				idauditoria:true,
				desestado:true,
				fecregistro:true
			}
		}
	}
}
</script>
